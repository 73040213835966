<template>
  <TrainingCard
    :is-view="isView"
    :training="training"
    class="mb-8"
    @click="$emit('click')"
  >
    <template #more-content>
      <VLine :label="$t('app.prerequisites', 2)" />

      <div class="flex flex-wrap">
        <div
          v-for="(value, index) in prerequisites"
          :key="value.id"
          class="flex"
          :class="index !== prerequisites.length - 1 ? 'comma' : ''"
        >
          {{ getText(value.texts, "name") }}
        </div>
      </div>

      <VLine :label="$t('app.mandatory_for_roles')" />

      <div class="flex flex-wrap">
        <div
          v-for="(value, index) in roles"
          :key="value.id"
          class="flex"
          :class="index !== roles.length - 1 ? 'comma' : ''"
        >
          <slot name="role" :role="value">
            {{ value.name }}
          </slot>
        </div>
      </div>

      <VLine :label="$t('app.interval_after_prerequisite')" />

      <div class="flex">
        {{ training?.reregistration_delay_after_prerequisites }}
        {{ $t("app.days") }}
      </div>

      <VLine :label="$t('app.maximum_registration_duration')" />

      <div class="flex">
        {{ training?.completion_deadline_days }} {{ $t("app.days") }}
      </div>

      <VLine :label="$t('app.places_available')" />

      <div class="flex">
        {{ training?.available_registrations }}
      </div>
    </template>
  </TrainingCard>
</template>

<script>
import { computed } from "vue";
// Composables
import useTexts from "@/composables/useTexts";
// Components
import TrainingCard from "./TrainingCard";
import VLine from "@/components/VLine";

export default {
  components: {
    TrainingCard,
    VLine
  },
  props: {
    training: {
      type: Object,
      required: true
    },
    isView: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click"],
  setup(props) {
    // Composables
    const { getText } = useTexts();

    // Computed
    const prerequisites = computed(() => {
      return props.training?.prerequisites ?? [];
    });

    const roles = computed(() => {
      return props.training?.roles ?? [];
    });

    return {
      prerequisites,
      roles,
      // useTexts
      getText
    };
  }
};
</script>
